
var QueryString = function () {
  // This function is anonymous, is executed immediately and 
  // the return value is assigned to QueryString!
  var query_string = {};
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i=0;i<vars.length;i++) {
    var pair = vars[i].split("=");
        // If first entry with this name
    if (typeof query_string[pair[0]] === "undefined") {
      query_string[pair[0]] = decodeURIComponent(pair[1]);
        // If second entry with this name
    } else if (typeof query_string[pair[0]] === "string") {
      var arr = [ query_string[pair[0]],decodeURIComponent(pair[1]) ];
      query_string[pair[0]] = arr;
        // If third or later entry with this name
    } else {
      query_string[pair[0]].push(decodeURIComponent(pair[1]));
    }
  } 
  return query_string;
}();

$(document).ready(function(){

    $(".js-owl-carousel-main").owlCarousel({
        navRewind: false,
        lazyLoad: true,
        center:true,
        items:1,
        loop: false,
        autoWidth:false,
        autoplay:true,
        autoplayTimeout:3000,
        autoplayHoverPause:true,
        animateOut: 'fadeOut',
        nav    : true,
        navText : ["<i class='fa fa-chevron-left'></i>","<i class='fa fa-chevron-right'></i>"],
        responsive:{

        },
        dots: true

    });

    $(".js-owl-carousel").owlCarousel({
        navRewind: false,
        lazyLoad: true,
        center:true,
        items:3,
        loop: true,
        autoWidth:true,
        autoplay:true,
        autoplayTimeout:3000,
        autoplayHoverPause:true,
        animateOut: 'fadeOut',
        nav    : true,
        navText : ["<i class='fa fa-chevron-left'></i>","<i class='fa fa-chevron-right'></i>"],
        responsive:{
            600:{
                items:4
            }
        },
        dots: true

    });

    if ($('.js-add-personalize').length) {
        $('.js-add-personalize').on('click', function(e) {
            e.preventDefault();
            var dataLayer = $(this).data('personalize');
            $('#item-'+dataLayer).removeClass('hide');
            $(this).addClass('hide');
        });
    }

    if ($('.js-open-gallery').length) {
        $('.js-open-gallery').summitLightbox();
    }

    if(window.location.hash) {

        // $('body,html').animate({                
        //     scrollTop: $(window.location.hash).offset().top-100
        // },1000);
        
        $('body,html').scrollTop($(window.location.hash).offset().top-300);
    }

    $(window).on('hashchange', function(e) {
        
        e.preventDefault();
        // alert($(window.location.hash).offset().top);
        $('body,html').scrollTop($(window.location.hash).offset().top-100);
    });


});

$(window).load(function() {
    if( typeof QueryString.goto != 'undefined' && QueryString.goto != '' ){
        // alert(QueryString.goto)
        goToHash('#'+QueryString.goto, 1);
    }
});

function goToHash(hash, animate){

    // alert('goToHash('+hash+')')
    // alert($(hash).offset().top)
    // $('body,html').scrollTop($(hash).offset().top-100);
    var top_hash = $(hash).offset().top;
    // console.log(top_hash);
    // window.scrollTo( 0, top_hash);

    setTimeout(function(){
        // window.scrollTo( 0, top_hash);

        if( typeof animate != 'undefined' && animate != '' ){

            // $('body,html').animate({                
            //     scrollTop: $(hash).offset().top-100
            // },300);
            $('body,html').scrollTop($(hash).offset().top-100);
        }
        else{

            $('body,html').scrollTop($(hash).offset().top-100);
        }
        // console.log(top_hash);
    }, 1);
}

// Instafeed

var feed = new Instafeed({
    get: 'user',
    userId: 1225247768,
    accessToken: '1225247768.990714c.b943f5c06fe14436aec406f13f063a09',
    template: '<a href="{{link}}" target="_blank"><img src="{{image}}" /></a>'
});
feed.run();

//Cookies

function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

$(window).load(function () {
    if (getCookie('pl_cookies_aceptadas') == "") {
        $('.js-cookies-alert').show();
    } else {
        $('.js-cookies-alert').hide();
    }

    if (document.cookie.indexOf("pl_cookies_aceptadas") >= 0) {
        $('.js-cookies-alert').hide();
    } else {
        $('.js-tipso-search').show();
    }
});

$('.js-cookies-close').on('click', function () {
    var exdate = new Date();
    exdate.setDate(exdate.getDate() + 3650);
    var c_value = escape(1) + ((3650 === null) ? "" : "; expires=" + exdate.toUTCString());
    document.cookie = 'pl_cookies_aceptadas' + "=" + c_value;
    $('.js-cookies-alert').remove();
});